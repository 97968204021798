import React from 'react';
import Swal from 'sweetalert2';

function Form(){

    const handleClick = () => {
    
          Swal.fire({
            icon: "success",
            title: "Спасибо, что заполнили форму! Менеджер в скором времени свяжется с Вами!",
            
            width: 600,
            timer: 2500,
            showConfirmButton: false,
            padding: "1.5em",
            color: "#fff",
            background: "#1a1a1a"
            // backdrop: `
            //   rgba(0,0,123,0.4)
            //   url("/images/nyan-cat.gif")
            //   center
            //   no-repeat
            // `
          });
    }
 return(
    <>
    <div id='formbox'>
        <h1>Заполни форму<br/>и мы расскажем обо всех<br/>абонементах и 🔥 акциях!</h1>
        <div className='form'>
            <input type='text' placeholder='Имя*' required />
            <input type='phone' placeholder='Номер телефона*' required />
            <input onClick={handleClick} type='submit' value='Отправить заявку' />
        </div>

    </div>
    </>
 )
}

export default Form;