import React from 'react';
import Header from './components/heder/headerBlock';
import Descrcards from './components/descr-cards/Descrcards';
import GiftCard from './components/gift-cards/giftcardBlock';
import Form from './components/form/formBlock';
import Footer from './components/footer/footerBlock';
import './index.css';

function App() {
  return (
    <div className="App">
      <Header/>
      <Descrcards/>
      <GiftCard />
      <Form />
      <Footer />

    </div>
  );
}

export default App;
