import React, {useState} from 'react';
import { Autoplay, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';
import {Link} from 'react-scroll';




function Slider () {
  return (
    <Swiper
    modules={[Autoplay, Pagination]}
      spaceBetween={50}
      slidesPerView="auto"
      autoplay={2000}
      pagination={{ clickable: true }}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide className='res-slide'>
        <h1>5 сеансов массажа</h1>
        <Link to="formbox" smooth={true} duration={1000}>получить предложение</Link>
      </SwiperSlide>
      <SwiperSlide className='res-slide'>
      <h1>10 персональных треннировок</h1>
      <Link to="formbox" smooth={true} duration={1000}>получить предложение</Link>
      </SwiperSlide>
      <SwiperSlide className='res-slide'>
      <h1>2 месяца заморозки</h1>
      <Link to="formbox" smooth={true} duration={1000}>получить предложение</Link>
      </SwiperSlide>


    </Swiper>
  );
};
export default Slider;