import React, { Component } from 'react';
import gift from '../../resources/svg/gift.png';
import Slider from './slider';
import arrow from '../../resources/svg/arrow.svg';



function GiftCard() {
 return(
    <div id='giftcards'>
            <img className="emoji" src={gift}/>
            <h1><span>Получайте подарки</span><br/>приобретая самую выгодную<br/> крубную карту!</h1>
            <div className='arrow'><img src={arrow} alt='arrow' /></div>
            <div className="gift-container">
                
             <Slider />
            </div>
    </div>
 )
}

export default GiftCard;