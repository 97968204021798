import React from "react";
import phone from "../../resources/svg/telephone.svg";
import loc from "../../resources/svg/location.svg";



function Footer () {

    return (
        <>
        <div className="footer">
            <div className="f-container">
            <h1>КОНТАКТЫ</h1>
                        <div className="phonenumber">
                            <img alt="phone" src={phone}/>
                            <a href="tel:+37360000707">+373 60 000 707</a>
                            <a href="tel:+37322-000-707">022-000-707</a>
                        </div>
                        <div className="location">
                            <img alt="loc" src={loc}/>
                            <p>Botanica, str.<br/>Grenoble 259/ 16</p>
                        </div>
                        
            </div>
            
        </div>
        <div className="copyright">
                            <div className="details">Copyright © 2009-2024. Фитнес клуб Fitness One</div>
                        </div>
        </>
    )
}
export default Footer;