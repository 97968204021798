import React from "react";
import DescrcardsBlock from "./DescrcardsBlock";
import star from '../../resources/svg/star.png'
import dumbell from '../../resources/svg/dumbell.png'
import basin from '../../resources/svg/basin.png'
import watch from '../../resources/svg/watch.png'
import step from '../../resources/svg/step.png'
import hama from '../../resources/svg/hama.png'
import lounge from '../../resources/svg/lounge.png'

function Descrcards(){
    return (
        <div id="descrcards">
            <img className="emoji" src={star}/>
            <h1>В вашем распоряжении всё необходимое<br/>для достижения тела мечты!</h1>
        <div className="a-container">
        <DescrcardsBlock image={dumbell} alt="dumbell" title="Тренажерный зал"/>
        <DescrcardsBlock image={basin} alt="basin" title="25-ти метровый бассейн"/>
        <DescrcardsBlock image={watch} alt="watch" title="Функциональная и кардио зоны"/>
        <DescrcardsBlock image={step} alt="step" title="Зал для групповых тренировок"/>
        <DescrcardsBlock image={hama} alt="hama" title="Финская сауна, турецкий хаммам и джакузи"/>
        <DescrcardsBlock image={lounge} alt="lounge" title="Открытая летняя площадка для загара"/>

        </div>
        </div>
    )
}

export default Descrcards;