import React, {useState} from "react";
import logo from './../../resources/svg/logo.svg';
import {Link} from 'react-scroll';


function Header(){
    return (
        <div id='main'>
            <div className="logo"><img src={logo} alt="fitness one" /></div>

            
            <div className='header-heading'>
                <h1>Период подарков</h1>
                <h4>от <span>FITNESS ONE</span></h4>
                <p className="details">
                    <ul className="list">
                    <li>5 сеансов массажа</li>
                    <li>10 персональных тренировок</li>
                    <li>2 месяца заморозки</li>
                    </ul>
                    </p>
             <div className="header-btns">
                <Link to="giftcards" smooth={true} duration={1000} className="header-btn">ПОДРОБНЕЕ</Link>
                </div>
            <div className="action">
                <p>АКЦИЯ ДЕЙСТВУЕТ ДО 31 АВГУСТА!</p>
                </div>
            </div>
            
           
        </div>
    )
}
export default Header;